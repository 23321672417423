exports.components = {
  "component---src-layout-faq-template-tsx": () => import("./../../../src/layout/FaqTemplate.tsx" /* webpackChunkName: "component---src-layout-faq-template-tsx" */),
  "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-en-additional-agreement-ee-mdx": () => import("./../../../src/layout/PolicyPageTemplate.tsx?__contentFilePath=/usr/src/app/src/policyPages/en/additional-agreement-ee.mdx" /* webpackChunkName: "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-en-additional-agreement-ee-mdx" */),
  "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-en-additional-agreement-ee-new-mdx": () => import("./../../../src/layout/PolicyPageTemplate.tsx?__contentFilePath=/usr/src/app/src/policyPages/en/additional-agreement-ee-new.mdx" /* webpackChunkName: "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-en-additional-agreement-ee-new-mdx" */),
  "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-en-additional-agreement-mdx": () => import("./../../../src/layout/PolicyPageTemplate.tsx?__contentFilePath=/usr/src/app/src/policyPages/en/additional-agreement.mdx" /* webpackChunkName: "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-en-additional-agreement-mdx" */),
  "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-en-additional-agreement-new-mdx": () => import("./../../../src/layout/PolicyPageTemplate.tsx?__contentFilePath=/usr/src/app/src/policyPages/en/additional-agreement-new.mdx" /* webpackChunkName: "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-en-additional-agreement-new-mdx" */),
  "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-en-claim-submission-and-examination-procedure-mdx": () => import("./../../../src/layout/PolicyPageTemplate.tsx?__contentFilePath=/usr/src/app/src/policyPages/en/claim-submission-and-examination-procedure.mdx" /* webpackChunkName: "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-en-claim-submission-and-examination-procedure-mdx" */),
  "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-en-cookie-policy-mdx": () => import("./../../../src/layout/PolicyPageTemplate.tsx?__contentFilePath=/usr/src/app/src/policyPages/en/cookie-policy.mdx" /* webpackChunkName: "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-en-cookie-policy-mdx" */),
  "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-en-deposit-trust-account-agreement-mdx": () => import("./../../../src/layout/PolicyPageTemplate.tsx?__contentFilePath=/usr/src/app/src/policyPages/en/deposit-trust-account-agreement.mdx" /* webpackChunkName: "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-en-deposit-trust-account-agreement-mdx" */),
  "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-en-general-service-agreement-mdx": () => import("./../../../src/layout/PolicyPageTemplate.tsx?__contentFilePath=/usr/src/app/src/policyPages/en/general-service-agreement.mdx" /* webpackChunkName: "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-en-general-service-agreement-mdx" */),
  "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-en-information-security-policy-mdx": () => import("./../../../src/layout/PolicyPageTemplate.tsx?__contentFilePath=/usr/src/app/src/policyPages/en/information-security-policy.mdx" /* webpackChunkName: "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-en-information-security-policy-mdx" */),
  "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-en-old-cookie-policy-2022-08-16-mdx": () => import("./../../../src/layout/PolicyPageTemplate.tsx?__contentFilePath=/usr/src/app/src/policyPages/en/old/cookie-policy-2022-08-16.mdx" /* webpackChunkName: "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-en-old-cookie-policy-2022-08-16-mdx" */),
  "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-en-old-cookie-policy-2024-01-02-mdx": () => import("./../../../src/layout/PolicyPageTemplate.tsx?__contentFilePath=/usr/src/app/src/policyPages/en/old/cookie-policy-2024-01-02.mdx" /* webpackChunkName: "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-en-old-cookie-policy-2024-01-02-mdx" */),
  "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-en-old-general-service-agreement-2022-07-28-mdx": () => import("./../../../src/layout/PolicyPageTemplate.tsx?__contentFilePath=/usr/src/app/src/policyPages/en/old/general-service-agreement-2022-07-28.mdx" /* webpackChunkName: "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-en-old-general-service-agreement-2022-07-28-mdx" */),
  "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-en-old-general-service-agreement-2024-06-09-mdx": () => import("./../../../src/layout/PolicyPageTemplate.tsx?__contentFilePath=/usr/src/app/src/policyPages/en/old/general-service-agreement-2024-06-09.mdx" /* webpackChunkName: "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-en-old-general-service-agreement-2024-06-09-mdx" */),
  "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-en-old-information-security-policy-2022-11-07-mdx": () => import("./../../../src/layout/PolicyPageTemplate.tsx?__contentFilePath=/usr/src/app/src/policyPages/en/old/information-security-policy-2022-11-07.mdx" /* webpackChunkName: "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-en-old-information-security-policy-2022-11-07-mdx" */),
  "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-en-old-payment-account-agreement-2022-07-28-mdx": () => import("./../../../src/layout/PolicyPageTemplate.tsx?__contentFilePath=/usr/src/app/src/policyPages/en/old/payment-account-agreement-2022-07-28.mdx" /* webpackChunkName: "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-en-old-payment-account-agreement-2022-07-28-mdx" */),
  "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-en-old-payment-account-agreement-2024-06-09-mdx": () => import("./../../../src/layout/PolicyPageTemplate.tsx?__contentFilePath=/usr/src/app/src/policyPages/en/old/payment-account-agreement-2024-06-09.mdx" /* webpackChunkName: "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-en-old-payment-account-agreement-2024-06-09-mdx" */),
  "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-en-old-payment-card-agreement-2024-06-09-mdx": () => import("./../../../src/layout/PolicyPageTemplate.tsx?__contentFilePath=/usr/src/app/src/policyPages/en/old/payment-card-agreement-2024-06-09.mdx" /* webpackChunkName: "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-en-old-payment-card-agreement-2024-06-09-mdx" */),
  "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-en-old-periodic-payment-agreement-2022-07-28-mdx": () => import("./../../../src/layout/PolicyPageTemplate.tsx?__contentFilePath=/usr/src/app/src/policyPages/en/old/periodic-payment-agreement-2022-07-28.mdx" /* webpackChunkName: "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-en-old-periodic-payment-agreement-2022-07-28-mdx" */),
  "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-en-old-periodic-payment-agreement-2024-06-09-mdx": () => import("./../../../src/layout/PolicyPageTemplate.tsx?__contentFilePath=/usr/src/app/src/policyPages/en/old/periodic-payment-agreement-2024-06-09.mdx" /* webpackChunkName: "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-en-old-periodic-payment-agreement-2024-06-09-mdx" */),
  "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-en-old-privacy-policy-2022-05-19-mdx": () => import("./../../../src/layout/PolicyPageTemplate.tsx?__contentFilePath=/usr/src/app/src/policyPages/en/old/privacy-policy-2022-05-19.mdx" /* webpackChunkName: "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-en-old-privacy-policy-2022-05-19-mdx" */),
  "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-en-old-privacy-policy-2022-12-14-mdx": () => import("./../../../src/layout/PolicyPageTemplate.tsx?__contentFilePath=/usr/src/app/src/policyPages/en/old/privacy-policy-2022-12-14.mdx" /* webpackChunkName: "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-en-old-privacy-policy-2022-12-14-mdx" */),
  "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-en-payment-account-agreement-mdx": () => import("./../../../src/layout/PolicyPageTemplate.tsx?__contentFilePath=/usr/src/app/src/policyPages/en/payment-account-agreement.mdx" /* webpackChunkName: "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-en-payment-account-agreement-mdx" */),
  "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-en-payment-card-agreement-mdx": () => import("./../../../src/layout/PolicyPageTemplate.tsx?__contentFilePath=/usr/src/app/src/policyPages/en/payment-card-agreement.mdx" /* webpackChunkName: "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-en-payment-card-agreement-mdx" */),
  "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-en-periodic-payment-agreement-mdx": () => import("./../../../src/layout/PolicyPageTemplate.tsx?__contentFilePath=/usr/src/app/src/policyPages/en/periodic-payment-agreement.mdx" /* webpackChunkName: "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-en-periodic-payment-agreement-mdx" */),
  "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-en-privacy-policy-mdx": () => import("./../../../src/layout/PolicyPageTemplate.tsx?__contentFilePath=/usr/src/app/src/policyPages/en/privacy-policy.mdx" /* webpackChunkName: "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-en-privacy-policy-mdx" */),
  "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-lt-bendroji-paslaugu-sutartis-mdx": () => import("./../../../src/layout/PolicyPageTemplate.tsx?__contentFilePath=/usr/src/app/src/policyPages/lt/bendroji-paslaugu-sutartis.mdx" /* webpackChunkName: "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-lt-bendroji-paslaugu-sutartis-mdx" */),
  "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-lt-depozitines-saskaitos-sutartis-mdx": () => import("./../../../src/layout/PolicyPageTemplate.tsx?__contentFilePath=/usr/src/app/src/policyPages/lt/depozitines-saskaitos-sutartis.mdx" /* webpackChunkName: "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-lt-depozitines-saskaitos-sutartis-mdx" */),
  "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-lt-informacijos-saugumo-politika-mdx": () => import("./../../../src/layout/PolicyPageTemplate.tsx?__contentFilePath=/usr/src/app/src/policyPages/lt/informacijos-saugumo-politika.mdx" /* webpackChunkName: "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-lt-informacijos-saugumo-politika-mdx" */),
  "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-lt-mokejimo-korteliu-sutartis-mdx": () => import("./../../../src/layout/PolicyPageTemplate.tsx?__contentFilePath=/usr/src/app/src/policyPages/lt/mokejimo-korteliu-sutartis.mdx" /* webpackChunkName: "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-lt-mokejimo-korteliu-sutartis-mdx" */),
  "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-lt-old-bendroji-paslaugu-sutartis-2022-07-28-mdx": () => import("./../../../src/layout/PolicyPageTemplate.tsx?__contentFilePath=/usr/src/app/src/policyPages/lt/old/bendroji-paslaugu-sutartis-2022-07-28.mdx" /* webpackChunkName: "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-lt-old-bendroji-paslaugu-sutartis-2022-07-28-mdx" */),
  "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-lt-old-bendroji-paslaugu-sutartis-2024-06-09-mdx": () => import("./../../../src/layout/PolicyPageTemplate.tsx?__contentFilePath=/usr/src/app/src/policyPages/lt/old/bendroji-paslaugu-sutartis-2024-06-09.mdx" /* webpackChunkName: "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-lt-old-bendroji-paslaugu-sutartis-2024-06-09-mdx" */),
  "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-lt-old-informacijos-saugumo-politika-2022-11-07-mdx": () => import("./../../../src/layout/PolicyPageTemplate.tsx?__contentFilePath=/usr/src/app/src/policyPages/lt/old/informacijos-saugumo-politika-2022-11-07.mdx" /* webpackChunkName: "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-lt-old-informacijos-saugumo-politika-2022-11-07-mdx" */),
  "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-lt-old-mokejimo-korteliu-sutartis-2024-06-09-mdx": () => import("./../../../src/layout/PolicyPageTemplate.tsx?__contentFilePath=/usr/src/app/src/policyPages/lt/old/mokejimo-korteliu-sutartis-2024-06-09.mdx" /* webpackChunkName: "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-lt-old-mokejimo-korteliu-sutartis-2024-06-09-mdx" */),
  "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-lt-old-periodiniu-mokejimu-sutartis-2022-07-28-mdx": () => import("./../../../src/layout/PolicyPageTemplate.tsx?__contentFilePath=/usr/src/app/src/policyPages/lt/old/periodiniu-mokejimu-sutartis-2022-07-28.mdx" /* webpackChunkName: "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-lt-old-periodiniu-mokejimu-sutartis-2022-07-28-mdx" */),
  "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-lt-old-periodiniu-mokejimu-sutartis-2024-06-09-mdx": () => import("./../../../src/layout/PolicyPageTemplate.tsx?__contentFilePath=/usr/src/app/src/policyPages/lt/old/periodiniu-mokejimu-sutartis-2024-06-09.mdx" /* webpackChunkName: "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-lt-old-periodiniu-mokejimu-sutartis-2024-06-09-mdx" */),
  "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-lt-old-privatumo-politika-2022-05-19-mdx": () => import("./../../../src/layout/PolicyPageTemplate.tsx?__contentFilePath=/usr/src/app/src/policyPages/lt/old/privatumo-politika-2022-05-19.mdx" /* webpackChunkName: "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-lt-old-privatumo-politika-2022-05-19-mdx" */),
  "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-lt-old-privatumo-politika-2022-12-14-mdx": () => import("./../../../src/layout/PolicyPageTemplate.tsx?__contentFilePath=/usr/src/app/src/policyPages/lt/old/privatumo-politika-2022-12-14.mdx" /* webpackChunkName: "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-lt-old-privatumo-politika-2022-12-14-mdx" */),
  "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-lt-old-saskaitos-sutartis-2022-07-28-mdx": () => import("./../../../src/layout/PolicyPageTemplate.tsx?__contentFilePath=/usr/src/app/src/policyPages/lt/old/saskaitos-sutartis-2022-07-28.mdx" /* webpackChunkName: "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-lt-old-saskaitos-sutartis-2022-07-28-mdx" */),
  "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-lt-old-saskaitos-sutartis-2024-06-09-mdx": () => import("./../../../src/layout/PolicyPageTemplate.tsx?__contentFilePath=/usr/src/app/src/policyPages/lt/old/saskaitos-sutartis-2024-06-09.mdx" /* webpackChunkName: "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-lt-old-saskaitos-sutartis-2024-06-09-mdx" */),
  "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-lt-old-slapuku-politika-2022-08-16-mdx": () => import("./../../../src/layout/PolicyPageTemplate.tsx?__contentFilePath=/usr/src/app/src/policyPages/lt/old/slapuku-politika-2022-08-16.mdx" /* webpackChunkName: "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-lt-old-slapuku-politika-2022-08-16-mdx" */),
  "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-lt-old-slapuku-politika-2024-01-02-mdx": () => import("./../../../src/layout/PolicyPageTemplate.tsx?__contentFilePath=/usr/src/app/src/policyPages/lt/old/slapuku-politika-2024-01-02.mdx" /* webpackChunkName: "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-lt-old-slapuku-politika-2024-01-02-mdx" */),
  "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-lt-papildomas-susitarimas-ee-mdx": () => import("./../../../src/layout/PolicyPageTemplate.tsx?__contentFilePath=/usr/src/app/src/policyPages/lt/papildomas-susitarimas-ee.mdx" /* webpackChunkName: "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-lt-papildomas-susitarimas-ee-mdx" */),
  "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-lt-papildomas-susitarimas-ee-naujas-mdx": () => import("./../../../src/layout/PolicyPageTemplate.tsx?__contentFilePath=/usr/src/app/src/policyPages/lt/papildomas-susitarimas-ee-naujas.mdx" /* webpackChunkName: "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-lt-papildomas-susitarimas-ee-naujas-mdx" */),
  "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-lt-papildomas-susitarimas-mdx": () => import("./../../../src/layout/PolicyPageTemplate.tsx?__contentFilePath=/usr/src/app/src/policyPages/lt/papildomas-susitarimas.mdx" /* webpackChunkName: "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-lt-papildomas-susitarimas-mdx" */),
  "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-lt-papildomas-susitarimas-naujas-mdx": () => import("./../../../src/layout/PolicyPageTemplate.tsx?__contentFilePath=/usr/src/app/src/policyPages/lt/papildomas-susitarimas-naujas.mdx" /* webpackChunkName: "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-lt-papildomas-susitarimas-naujas-mdx" */),
  "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-lt-periodiniu-mokejimu-sutartis-mdx": () => import("./../../../src/layout/PolicyPageTemplate.tsx?__contentFilePath=/usr/src/app/src/policyPages/lt/periodiniu-mokejimu-sutartis.mdx" /* webpackChunkName: "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-lt-periodiniu-mokejimu-sutartis-mdx" */),
  "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-lt-pretenziju-pateikimo-ir-nagrinejimo-tvarka-mdx": () => import("./../../../src/layout/PolicyPageTemplate.tsx?__contentFilePath=/usr/src/app/src/policyPages/lt/pretenziju-pateikimo-ir-nagrinejimo-tvarka.mdx" /* webpackChunkName: "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-lt-pretenziju-pateikimo-ir-nagrinejimo-tvarka-mdx" */),
  "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-lt-privatumo-politika-mdx": () => import("./../../../src/layout/PolicyPageTemplate.tsx?__contentFilePath=/usr/src/app/src/policyPages/lt/privatumo-politika.mdx" /* webpackChunkName: "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-lt-privatumo-politika-mdx" */),
  "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-lt-saskaitos-sutartis-mdx": () => import("./../../../src/layout/PolicyPageTemplate.tsx?__contentFilePath=/usr/src/app/src/policyPages/lt/saskaitos-sutartis.mdx" /* webpackChunkName: "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-lt-saskaitos-sutartis-mdx" */),
  "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-lt-slapuku-politika-mdx": () => import("./../../../src/layout/PolicyPageTemplate.tsx?__contentFilePath=/usr/src/app/src/policyPages/lt/slapuku-politika.mdx" /* webpackChunkName: "component---src-layout-policy-page-template-tsx-content-file-path-src-policy-pages-lt-slapuku-politika-mdx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-business-account-tsx": () => import("./../../../src/pages/business-account.tsx" /* webpackChunkName: "component---src-pages-business-account-tsx" */),
  "component---src-pages-card-solutions-tsx": () => import("./../../../src/pages/card-solutions.tsx" /* webpackChunkName: "component---src-pages-card-solutions-tsx" */),
  "component---src-pages-contact-form-tsx": () => import("./../../../src/pages/contact/form.tsx" /* webpackChunkName: "component---src-pages-contact-form-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-generator-instructions-tsx": () => import("./../../../src/pages/generator-instructions.tsx" /* webpackChunkName: "component---src-pages-generator-instructions-tsx" */),
  "component---src-pages-holidays-tsx": () => import("./../../../src/pages/holidays.tsx" /* webpackChunkName: "component---src-pages-holidays-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kyc-services-tsx": () => import("./../../../src/pages/kyc-services.tsx" /* webpackChunkName: "component---src-pages-kyc-services-tsx" */),
  "component---src-pages-legal-tsx": () => import("./../../../src/pages/legal.tsx" /* webpackChunkName: "component---src-pages-legal-tsx" */),
  "component---src-pages-personal-account-tsx": () => import("./../../../src/pages/personal-account.tsx" /* webpackChunkName: "component---src-pages-personal-account-tsx" */),
  "component---src-pages-pricing-2022-07-01-tsx": () => import("./../../../src/pages/pricing/2022-07-01.tsx" /* webpackChunkName: "component---src-pages-pricing-2022-07-01-tsx" */),
  "component---src-pages-pricing-2023-08-16-tsx": () => import("./../../../src/pages/pricing/2023-08-16.tsx" /* webpackChunkName: "component---src-pages-pricing-2023-08-16-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-swift-payment-instructions-tsx": () => import("./../../../src/pages/swift-payment-instructions.tsx" /* webpackChunkName: "component---src-pages-swift-payment-instructions-tsx" */),
  "component---src-pages-white-label-solutions-tsx": () => import("./../../../src/pages/white-label-solutions.tsx" /* webpackChunkName: "component---src-pages-white-label-solutions-tsx" */)
}

